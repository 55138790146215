import ArticleBody from '@components/ArticleBody';
import ArticleHeader from '@components/ArticleHeader';
import ArticleList from '@components/ArticleList';
import Date from '@components/Date';
import Meta from '@components/Meta';
import useTranslation from 'next-translate/useTranslation';

export default function Article({
  title,
  image,
  publicationDate,
  category,
  preamble,
  body,
  recentArticles,
}) {
  const { t } = useTranslation('articles');
  const tCategory = t(`category-${category}`);

  return (
    <article className='pt-5 md:pt-0'>
      <Meta />
      <ArticleHeader title={title} image={image} />
      <div className='grid lg:grid-cols-[1fr,360px] gap-16 mt-4 md:mt-8'>
        <div className='max-w-2xl'>
          <div>
            <Date dateString={publicationDate} prefix='Publicerad ' />
            {category && (
              <span className='text-xs uppercase'>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <span className='text-turquoise'>{tCategory}</span>
              </span>
            )}
          </div>
          <p className='max-w-2xl mt-4 text-xl font-semibold md:text-2xl'>
            {preamble}
          </p>
          <ArticleBody content={body} />
        </div>
        {recentArticles && recentArticles.length > 0 ? (
          <ArticleList articles={recentArticles} />
        ) : null}
      </div>
    </article>
  );
}
