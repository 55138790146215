import LoadingIndicator from '@components/LoadingIndicator';
import { useState } from 'react';

export default function ContactForm({ t }) {
  const [apiError, setApiError] = useState('');
  const [inputError, setInputError] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const tYourName = t('common:your-name');
  const tYourEmail = t('common:your-email');
  const tYourMessage = t('common:your-message');
  const tAllFieldsRequired = t('common:all-fields-required');
  const tSubmit = t('common:submit');
  const tSubmitting = t('common:submitting');
  const tThankYou = t('common:thank-you-for-your-message');
  const tSomethingWentWrong = t('common:something-went-wrong');

  function handleSubmit(e) {
    e.preventDefault();
    setApiError('');
    setInputError('');
    if (isSubmitting) return;
    setIsSubmitting(true);

    if (!name || !email || !message) {
      setInputError(tAllFieldsRequired);
      setIsSubmitting(false);
      return;
    }

    const data = {
      name,
      email,
      message,
    };

    return fetch('/api/contact', {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('Error while submitting contact form');
        }

        setSubmitted(true);
        setName('');
        setEmail('');
        setMessage('');
      })
      .catch((error) => {
        setApiError(tSomethingWentWrong);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  return (
    <aside>
      <form className='flex flex-col gap-6 text-base' onSubmit={handleSubmit}>
        <fieldset className='flex flex-col gap-2'>
          <label htmlFor='name' className='first-letter:uppercase'>
            {tYourName}
          </label>
          <input
            required
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            id='name'
            className={`block p-2 border rounded-lg border-interactive-inputfields${
              inputError ? ' text-error-svea' : ''
            }`}
            type='text'
          />
        </fieldset>
        <fieldset className='flex flex-col gap-2'>
          <label htmlFor='email' className='first-letter:uppercase'>
            {tYourEmail}
          </label>
          <input
            required
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            id='email'
            className={`block p-2 border rounded-lg border-interactive-inputfields${
              inputError ? ' text-error-svea' : ''
            }`}
            type='text'
          />
        </fieldset>
        <fieldset className='flex flex-col gap-2'>
          <label htmlFor='message' className='first-letter:uppercase'>
            {tYourMessage}
          </label>
          <textarea
            required
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            id='message'
            className={`block p-2 border rounded-lg border-interactive-inputfields${
              inputError ? ' text-error-svea' : ''
            }`}
          />
        </fieldset>
        <p className='text-sm first-letter:uppercase'>{tAllFieldsRequired}</p>
        {apiError || inputError ? (
          <div className='flex items-center justify-center py-2 bg-error-svea'>
            {apiError ? (
              <p className='text-error-svea-dark first-letter:uppercase'>
                {apiError}
              </p>
            ) : null}
            {inputError ? (
              <p className='text-error-svea-dark first-letter:uppercase'>
                {inputError}
              </p>
            ) : null}
          </div>
        ) : null}
        {submitted ? (
          <p className='flex items-center justify-center py-2 border border-dark-blue text-dark-blue'>
            <span className='first-letter:uppercase'>{tThankYou}</span>
          </p>
        ) : (
          <button
            type='submit'
            className='flex items-center justify-center py-2 text-white border rounded-full border-interactive-blue bg-interactive-blue'
          >
            <span className='first-letter:uppercase'>
              {isSubmitting ? tSubmitting : tSubmit}
            </span>
            {isSubmitting ? (
              <LoadingIndicator
                width={18}
                height={18}
                className='inline ml-2 text-white'
              />
            ) : null}
          </button>
        )}
      </form>
      {/* <p className='mt-4 font-bold first-letter:uppercase'>{tThankYou}</p> */}
    </aside>
  );
}
