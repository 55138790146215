import Date from '@components/Date';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';

export default function ArticleListItem({
  category,
  title,
  slug,
  publicationDate,
}) {
  const { t } = useTranslation('articles');
  const tArticles = t('articles');

  return (
    <li className='pb-5 border-b border-b-didiver-light last:border-0'>
      <Link href={`/${tArticles}/${slug}`}>
        <p className='text-xs uppercase text-turquoise'>{category}</p>
        <h6 className='text-xl'>{title}</h6>
        <Date dateString={publicationDate} />
      </Link>
    </li>
  );
}
