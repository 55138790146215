import Container from '@components/Container';
import Meta from '@components/Meta';
import { PageTypeSpecificData, PAGE_TYPE } from '@lib/types';
import clsx from 'clsx';

// import PreviewBar from '@components/PreviewBar';

interface ILayoutProps {
  pageType: PAGE_TYPE;
  heroContent?;
  pageTypeSpecificContent?: JSX.Element;
  pageContent?: any;
}

function layoutMainContent({ pageType, pageTypeSpecificContent, pageContent }) {
  switch (pageType) {
    case 'contact': {
      return (
        <div className='grid gap-4 md:gap-16 md:grid-cols-2'>
          {pageContent ?? null}
          {pageTypeSpecificContent ?? null}
        </div>
      );
    }
    case 'article':
    case 'subLandingPage':
    case 'articles':
    case 'search':
    case 'landingPage':
    case 'rootPage':
    default: {
      return (
        <>
          {pageTypeSpecificContent ? (
            <Container>{pageTypeSpecificContent}</Container>
          ) : null}
          {pageContent ? <Container>{pageContent}</Container> : null}
        </>
      );
    }
  }
}

export default function Layout({
  pageType,
  heroContent,
  pageTypeSpecificContent,
  pageContent,
}: ILayoutProps) {
  const mainClasses = clsx({
    'flex flex-col': true, // Always on
  });
  const contentWrapperClasses = clsx({
    'grid grid-cols-main': true, // Always on
  });

  return (
    <>
      <Meta />
      {/* <PreviewBar preview={preview} /> */}
      <div className='min-w-full mb-16 md:mb-32' id='portal-target-backdrop'>
        <main className={mainClasses}>
          {heroContent ?? null}
          <div className={contentWrapperClasses}>
            {layoutMainContent({
              pageType,
              pageTypeSpecificContent,
              pageContent,
            })}
          </div>
        </main>
      </div>
    </>
  );
}
