import {
  ARTICLE_QUERY,
  LANDING_PAGE_QUERY,
  ROOT_PAGE_QUERY,
  SITEMAP_ARTICLE_QUERY,
  SUB_LANDING_PAGE_QUERY,
} from '@lib/queries';
import {
  ContentfulArticle,
  ContentfulSys,
  IArticleFieldsWithRecentArticles,
  IPageFieldsWithSlugs,
  IRootPageFieldsWithSlugs,
  LOCALE,
  PAGE_TYPE,
  PageTypeSpecificData,
  Path,
  SitemapEntry,
  SitemapPage,
} from 'src/lib/types/index';
import {
  IArticleFields,
  IFooterFields,
  IPageFields,
  IRootPageFields,
  ISeo,
  ISubLandingPageFields,
} from '@lib/types/generated/contentful';

import { ContentfulCollection } from 'contentful';
import { SubLink } from '@components/SubLinkItem';
import { TopLink } from '@components/NavLinkItem';
import getT from 'next-translate/getT';

interface IPageWithCollection extends IPageFields {
  childPagesCollection?:
    | ContentfulCollection<ISubLandingPageFields>
    | undefined;
}

async function fetchGraphQL(query, preview = false) {
  return fetch(
    `https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_SPACE_ID}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          preview
            ? process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN
            : process.env.CONTENTFUL_ACCESS_TOKEN
        }`,
      },
      body: JSON.stringify({ query }),
    }
  ).then((response) => response.json());
}

function extractRootPage(fetchResponse): IRootPageFields & ContentfulSys {
  return fetchResponse?.data?.rootPageCollection?.items?.[0];
}

function extractFooter(fetchResponse): IFooterFields & ContentfulSys {
  return fetchResponse?.data?.footerCollection?.items?.[0];
}

function extractMenu(fetchResponse): TopLink[] {
  const root: IPageWithCollection[] =
    fetchResponse?.data?.rootPageCollection?.items?.[0]?.childPagesCollection
      ?.items;
  if (!root) return [];

  const menuData: TopLink[] = root
    .filter((page) => page.includeInMenu)
    .map((page) => {
      const { slug, pageName, childPagesCollection } = page;
      const pageData: TopLink = {
        title: pageName,
        url: `/${slug}`,
      };
      const subLinks: SubLink[] = childPagesCollection?.items?.map(
        (subPage) => {
          const { slug: subSlug, pageName: subPageName } = subPage;
          const subLink: SubLink = {
            title: subPageName,
            url: `/${slug}/${subSlug}`,
          };

          return subLink;
        }
      );

      if (subLinks && subLinks.length > 0) {
        pageData.subLinks = subLinks;
      }

      return pageData;
    });

  return menuData;
}

function extractSiteTree(fetchResponse): Path[] {
  const root =
    fetchResponse?.data?.rootPageCollection?.items?.[0]?.childPagesCollection
      ?.items;
  if (!root) return [];

  const paths: Path[] = [
    {
      params: {
        slug: [],
      },
    },
  ];

  root.forEach((pageData) => {
    const { slug, childPagesCollection } = pageData;
    const page = {
      params: {
        slug: [slug],
      },
    };
    paths.push(page);

    childPagesCollection?.items?.forEach((subPageData) => {
      const { slug: subSlug } = subPageData;
      const subPage = {
        params: {
          slug: [slug, subSlug],
        },
      };

      paths.push(subPage);
    });
  });

  return paths;
}

async function extractSiteMap(mainResponse, articles): Promise<SitemapPage[]> {
  const pages: IPageFieldsWithSlugs[] =
    mainResponse?.data?.rootPageCollection?.items?.[0]?.childPagesCollection
      ?.items;
  if (!pages) return [];

  const rootPage: IRootPageFieldsWithSlugs =
    mainResponse?.data?.rootPageCollection?.items?.[0];

  const paths: SitemapPage[] = [
    {
      slug_sv: process.env.SITE_URL,
      slug_en: `${process.env.SITE_URL}/en`,
      publishedAt: rootPage?.sys?.publishedAt,
    },
  ];
  const tSv = await getT('sv', 'articles');
  const tEn = await getT('en', 'articles');
  const tSvArticles = tSv('articles');
  const tEnArticles = tEn('articles');

  pages.forEach((pageData) => {
    const {
      slug_sv,
      slug_en,
      childPagesCollection,
      sys: { publishedAt },
    } = pageData;
    const page: SitemapPage = {
      publishedAt: publishedAt,
    };
    if (typeof slug_sv !== undefined)
      page.slug_sv = `${process.env.SITE_URL}/${slug_sv}`;
    if (typeof slug_en !== undefined)
      page.slug_en = `${process.env.SITE_URL}/en/${slug_en}`;
    paths.push(page);

    childPagesCollection?.items?.forEach((subPageData) => {
      const {
        slug_sv: subSlug_sv,
        slug_en: subSlug_en,
        sys: { publishedAt },
      } = subPageData;
      const subPage: SitemapEntry = {
        publishedAt: publishedAt,
      };
      if (typeof subSlug_sv !== undefined)
        subPage.slug_sv = `${process.env.SITE_URL}/${slug_sv}/${subSlug_sv}`;
      if (typeof subSlug_en !== undefined)
        subPage.slug_en = `${process.env.SITE_URL}/en/${slug_en}/${subSlug_en}`;
      paths.push(subPage);
    });
  });
  articles.forEach((articleData) => {
    const {
      slug_sv,
      slug_en,
      sys: { publishedAt },
    } = articleData;
    const page: SitemapPage = {
      publishedAt: publishedAt,
    };
    if (typeof slug_sv !== undefined)
      page.slug_sv = `${process.env.SITE_URL}/${tSvArticles}/${slug_sv}`;
    if (typeof slug_en !== undefined)
      page.slug_en = `${process.env.SITE_URL}/en/${tEnArticles}/${slug_en}`;
    paths.push(page);
  });

  return paths;
}

function extractLandingPage(fetchResponse): IPageFields & ContentfulSys {
  return fetchResponse?.data?.pageCollection?.items?.[0];
}

function extractSubLandingPage(
  fetchResponse
): ISubLandingPageFields & ContentfulSys {
  return fetchResponse?.data?.subLandingPageCollection?.items?.[0];
}

function extractArticle(fetchResponse): ContentfulArticle {
  return fetchResponse?.data?.articleCollection?.items?.[0];
}

function extractArticles(fetchResponse): ContentfulArticle[] {
  return fetchResponse?.data?.articleCollection?.items;
}

export async function getRootPage({
  locale,
}: {
  locale: LOCALE;
}): Promise<IRootPageFields & ContentfulSys> {
  const entries = await fetchGraphQL(
    `query {
      rootPageCollection(limit: 1, locale: "${locale}") {
        items {
          ${ROOT_PAGE_QUERY}
        }
      }
    }`
  );

  return extractRootPage(entries);
}

export async function getMenu({
  locale,
}: {
  locale: LOCALE;
}): Promise<TopLink[]> {
  const entries = await fetchGraphQL(
    `query {
      rootPageCollection(limit: 1, locale: "${locale}") {
        items {
          childPagesCollection {
            items {
              pageName
              slug
              includeInMenu
              childPagesCollection {
                items {
                  slug
                  pageName
                }
              }
            }
          }
        }
      }
    }`
  );

  return extractMenu(entries);
}

export async function getFooter({
  locale,
}: {
  locale: LOCALE;
}): Promise<IFooterFields & ContentfulSys> {
  const entries = await fetchGraphQL(
    `query {
      footerCollection(limit: 1, , locale: "${locale}") {
        items {
          __typename
          sys {
            id
          }
          image {
            url
            description
          }
          logo {
            url
            description
          }
          slogan
          copyrightAndAddress {
                      json
              }
          footerGroupsCollection(limit: 4) {
            items {
              __typename
              sys {
                id
              }
              title
              contentCollection {
                items {
                  ... on FooterLink {
                    __typename
                    sys {
                      id
                    }
                    slug
                    external
                    linkText
                  }
                  ... on ContentTypeRichText {
                    __typename
                    sys {
                      id
                    }
                    content {
                      json
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`
  );

  return extractFooter(entries);
}

export async function getSiteTree({
  locale,
}: {
  locale: LOCALE;
}): Promise<Path[]> {
  const entries = await fetchGraphQL(
    `query {
      rootPageCollection(limit: 1, locale: "${locale}") {
        items {
          childPagesCollection {
            items {
              __typename
              sys {
                publishedAt
              }
              pageName
              slug
              childPagesCollection {
                items {
                __typename
                sys {
                  publishedAt
                }
                parentSlug
                pageName
                slug
              }
            }
          }
        }
      } 
    }}`
  );

  return extractSiteTree(entries);
}

export async function getSiteMap(): Promise<SitemapPage[]> {
  const entries = await fetchGraphQL(
    `query {
      rootPageCollection(limit: 1) {
        items {
          sys {
            publishedAt
          }
          childPagesCollection {
            items {
              sys {
                publishedAt
              }
              slug_sv: slug(locale: "sv")
              slug_en: slug(locale: "en")
              childPagesCollection {
                items {
                  sys {
                    publishedAt
                  }
                  parentSlug_sv: parentSlug(locale: "sv")
                  parentSlug_en: parentSlug(locale: "en")
                  slug_sv: slug(locale: "sv")
                  slug_en: slug(locale: "en")
              }
            }
          }
        }
      } 
    }}`
  );

  const articles = await getArticlesForSitemap({ preview: false });

  return extractSiteMap(entries, articles);
}

export async function getLandingPage({
  preview,
  slug,
  locale,
}: {
  preview: boolean;
  slug: string;
  locale: LOCALE;
}): Promise<IPageFields & ContentfulSys> {
  const entry = await fetchGraphQL(
    `query {
      pageCollection(where: { slug: "${slug}" }, limit: 1, locale: "${locale}") {
        items {
          ${LANDING_PAGE_QUERY}
        }
      }
    }`
  );

  const extractedPage = extractLandingPage(entry);
  if (!extractedPage) {
    throw new Error('No landing page found');
  }
  return extractedPage;
}

export async function getSubLandingPage({
  preview,
  slug,
  locale,
}: {
  preview: boolean;
  slug: string;
  locale: LOCALE;
}): Promise<ISubLandingPageFields & ContentfulSys> {
  const entry = await fetchGraphQL(
    `query {
      subLandingPageCollection(where: { slug: "${slug}" }, limit: 1, locale: "${locale}") {
        items {
          ${SUB_LANDING_PAGE_QUERY}
        }
      }
    }`
  );

  const extractedPage = extractSubLandingPage(entry);
  if (!extractedPage) {
    throw new Error('No sub landing page found');
  }
  return extractedPage;
}

export async function getNArticles({
  preview,
  order = 'publicationDate_DESC',
  numberOfArticles = 3,
  locale,
}: {
  preview: boolean;
  order?: 'publicationDate_DESC' | 'publicationDate_ASC';
  numberOfArticles: number;
  locale: LOCALE;
}): Promise<ContentfulArticle[]> {
  const entries = await fetchGraphQL(
    `query {
      articleCollection(order: ${order}, preview: ${
      preview ? 'true' : 'false'
    }, limit: ${numberOfArticles}, locale: "${locale}") {
        items {
          ${ARTICLE_QUERY}
        }
      }
    }`,
    preview
  );

  return extractArticles(entries);
}

export async function getArticlesForSitemap({
  preview,
  order = 'publicationDate_DESC',
}: {
  preview: boolean;
  order?: 'publicationDate_DESC' | 'publicationDate_ASC';
}): Promise<ContentfulArticle[]> {
  const entries = await fetchGraphQL(
    `query {
      articleCollection(order: ${order}, preview: ${
      preview ? 'true' : 'false'
    }) {
        items {
          ${SITEMAP_ARTICLE_QUERY}
        }
      }
    }`,
    preview
  );

  return extractArticles(entries);
}

export async function getPageTypeArticles({
  preview,
  locale,
  numberOfArticles = 3,
}: {
  preview: boolean;
  locale: LOCALE;
  numberOfArticles?: number;
}): Promise<{ recentArticles: ContentfulArticle[] }> {
  const recentArticles = await getNArticles({
    preview,
    locale,
    numberOfArticles,
  });

  return { recentArticles };
}

export async function getPageTypeSpecifics({
  preview,
  locale,
  pageType,
}: {
  preview: boolean;
  locale: LOCALE;
  pageType: PAGE_TYPE;
}): Promise<PageTypeSpecificData> {
  switch (pageType) {
    case 'articles': {
      return {
        pageType: pageType,
        ...(await getPageTypeArticles({ preview, locale })),
      };
    }
    default:
    case 'search': {
      return {
        pageType: pageType,
        ...(await getPageTypeArticles({ preview, locale })),
      };
    }
  }
}

export async function getArticle({ preview, slug, locale }): Promise<{
  seo: ISeo;
  pageContentCollection: {
    items: [IArticleFieldsWithRecentArticles];
  };
}> {
  const article = await fetchGraphQL(
    `query {
      articleCollection(where: { slug: "${slug}" }, preview: ${
      preview ? 'true' : 'false'
    }, limit: 1, locale: "${locale}") {
        items {
          ${ARTICLE_QUERY}
        }
      }
    }`,
    preview
  );
  const recentArticles = await fetchGraphQL(
    `query {
      articleCollection(where: { slug_not_in: "${slug}" }, order: publicationDate_DESC, preview: ${
      preview ? 'true' : 'false'
    }, limit: 5, locale: "${locale}") {
        items {
          ${ARTICLE_QUERY}
        }
      }
    }`,
    preview
  );

  return {
    seo: extractArticle(article).seo,
    pageContentCollection: {
      items: [
        {
          ...extractArticle(article),
          recentArticles: extractArticles(recentArticles),
        },
      ],
    },
  };
}
