import { COUNTRY, LANGUAGE, LOCALE, PAGE_TYPE } from 'src/lib/types/index';
import {
  getArticle,
  getLandingPage,
  getRootPage,
  getSubLandingPage,
} from '@lib/api';

export function localeToCountry(locale: string): COUNTRY {
  switch (locale) {
    case 'en': {
      return 'United Kingdom';
    }
    default:
      return 'Sweden';
  }
}

export function localeToLanguage(locale: LOCALE): LANGUAGE {
  switch (locale) {
    case 'en': {
      return 'English';
    }
    default:
      return 'Swedish';
  }
}

export function pageTypeToApiCall(pageType: PAGE_TYPE): Function {
  switch (pageType) {
    case 'articles':
    case 'search':
    case 'contact':
    case 'landingPage': {
      return getLandingPage;
    }
    case 'article': {
      return getArticle;
    }
    case 'subLandingPage': {
      return getSubLandingPage;
    }
    default:
    case 'rootPage': {
      return getRootPage;
    }
  }
}

export function slugArrayToPageType(slugArray: string[] | []): PAGE_TYPE {
  const slugDepth = slugArray.length;

  switch (slugDepth) {
    case 1: {
      // Matches: Landing pages, Articles, Search
      // E.g: /vat-registration-in-the-eu => landingPage
      // E.g: /articles => articles
      // E.g: /search?q=search-term => search
      // E.g: /contact => contact
      const [pageType] = slugArray;

      if (pageType === 'articles' || pageType === 'artiklar') return 'articles';
      if (pageType === 'search' || pageType === 'sök') return 'search';
      if (pageType === 'contact' || pageType === 'kontakt') return 'contact';

      return 'landingPage';
    }
    case 2: {
      // Matches: SubLandingPage, Article
      // E.g: /vat-registration-in-the-eu/sweden
      // E.g: /articles/a-headline-slug
      const [pageType] = slugArray;

      if (pageType === 'articles' || pageType === 'artiklar') return 'article';

      return 'subLandingPage';
    }
    default:
    case 0: {
      return 'rootPage';
    }
  }
}
