import ContentfulImage from '@components/ContentfulImage';

export default function ArticleHeader({ title, image }) {
  return (
    <>
      <h1 className='md:mt-12 text-3xl md:text-[3rem] md:max-w-[30ch] leading-tight md:leading-none md:mb-12 mb-4'>
        {title}
      </h1>
      {image?.url ? (
        <div
          className={`relative aspect-video md:h-[465px] w-full overflow-hidden`}
        >
          <ContentfulImage
            src={image.url}
            contentfulWidth={1240}
            contentfulHeight={465}
            alt={image?.description}
            layout='fill'
            objectFit='cover'
            className='z-[-1]'
            priority
            f='face'
            fit='fill'
          />
        </div>
      ) : null}
    </>
  );
}
