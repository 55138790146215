import Bow from '@components/Bow';
import Container from '@components/Container';
import ContentfulImage from '@components/ContentfulImage';

interface HeroProps {
  title: string;
  subtitle: string;
  image: { url: string; description: string };
  fullWidth: boolean;
  bow: boolean;
  low: boolean;
  gradient: boolean;
}

export default function Hero(props: HeroProps) {
  const { title, subtitle, image, fullWidth, bow, low, gradient } = props;

  return (
    <div
      className={`relative overflow-hidden flex flex-col justify-center
      ${low ? ' h-48 md:h-[360px]' : ' h-48 md:h-[500px] lg:h-[600px]'}${
        fullWidth
          ? ' w-full px-0 col-span-4 mb-8 md:mb-20'
          : ' container mx-auto mb-6 px-5'
      }${
        gradient
          ? ' content-[""] after:absolute after:h-full after:w-full after:bg-gradient-to-r after:from-dark-blue after:to-transparent'
          : ''
      }${image?.url ? '' : ' bg-dark-blue'}`}
    >
      {title || subtitle ? (
        <Container className='z-10 px-0 md:px-5'>
          {title ? (
            <h1
              className={`text-center md:text-left text-white leading-tight md:leading-extra-tight${
                low ? ' text-2xl md:text-5xl' : ' text-3xl md:text-7xl'
              }${fullWidth ? ' max-w-[700px]' : ' text-center'}`}
            >
              {title}
            </h1>
          ) : null}
          {subtitle ? (
            <p
              className={`text-xl hidden md:block md:text-2xl text-white${
                fullWidth ? ' max-w-[700px]' : ' text-center'
              }`}
            >
              {subtitle}
            </p>
          ) : null}
        </Container>
      ) : null}
      {image?.url ? (
        <ContentfulImage
          src={image.url}
          alt={image.description}
          contentfulWidth={1800}
          contentfulHeight={696}
          layout='fill'
          objectFit='cover'
          fit='fill'
          priority
          f='face'
        />
      ) : null}
      {bow ? <Bow /> : null}
    </div>
  );
}
