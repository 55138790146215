import { BRAND_NAME } from '@lib/constants';
import { ISeoFields } from '@lib/types/generated/contentful';
import Head from 'next/head';

interface MetaProps {
  fields?: ISeoFields;
}

export default function MetaComponent({ fields }: MetaProps) {
  const meta =
    fields &&
    Object.entries(fields).reduce(
      (acc, [key, value]) => {
        if (!value && value !== false) return acc;

        switch (key) {
          case 'sys':
          case '__typename':
            break;
          case 'title':
            acc.tags.push(
              <title key='title-tag'>{`${value} | ${BRAND_NAME}`}</title>,
              <meta name='title' content={value} key={key} />,
              <meta property='og:title' content={value} key='og:title' />,
              <meta
                property='twitter:title'
                content={value}
                key='twitter:title'
              />
            );
            break;
          case 'description':
            acc.tags.push(
              <meta name='description' content={value} key={key} />,
              <meta
                property='og:description'
                content={value}
                key='og:description'
              />,
              <meta
                property='twitter:description'
                content={value}
                key='twitter:description'
              />
            );
            break;
          case 'image':
            if (value?.url) {
              acc.tags.push(
                <meta property='og:image' content={value.url} key='og:image' />,
                <meta
                  property='twitter:image'
                  content={value.url}
                  key='twitter:image'
                />
              );
            }
            break;
          case 'noIndex':
          case 'noFollow':
            const isLastRobotsTag = acc.robotsContent.length > 0;
            if (value) acc.robotsContent += isLastRobotsTag ? ' ' + key : key;
            if (isLastRobotsTag)
              acc.tags.push(
                <meta name='robots' content={acc.robotsContent} key='robots' />
              );
            break;
          case 'keywords':
            if (value?.join()) {
              acc.tags.push(
                <meta name='keywords' content={value.join(', ')} key={key} />
              );
            }
            break;
          default:
            acc.tags.push(<meta name={key} content={value} key={key} />);
            break;
        }

        return acc;
      },
      {
        tags: [],
        robotsContent: '',
      }
    );

  // TODO Add meta tags based on URL:s <meta property="og:url" content="https://metatags.io/">
  // <meta property="twitter:url" content="https://metatags.io/">

  return (
    <Head>
      <link rel='icon' href='/favicon/favicon.png' key='favicon.png' />
      <meta property='og:type' content='website' key='og:type' />
      <meta
        property='twitter:card'
        content='summary_large_image'
        key='twitter:card'
      />
      {meta?.tags.length ? meta.tags : null}
    </Head>
  );
}
