import { connectStats } from 'react-instantsearch-dom';
import useTranslation from 'next-translate/useTranslation';

const Stats = ({ processingTimeMS, nbHits, nbSortedHits, areHitsSorted }) => {
  const { t } = useTranslation('search');

  const tResultsFoundIn = t('results-found-in', {
    count: nbHits,
    numberOfHits: nbHits.toLocaleString(),
    // time: processingTimeMS,
  });

  return (
    <p className='mb-4 text-sm'>
      {areHitsSorted && nbHits !== nbSortedHits
        ? `${nbSortedHits.toLocaleString()} relevant results sorted out of ${nbHits.toLocaleString()} found in ${processingTimeMS.toLocaleString()}ms`
        : `${tResultsFoundIn}`}
    </p>
  );
};

const CustomStats = connectStats(Stats);

export default CustomStats;
