import * as RadixNavigationMenu from '@radix-ui/react-navigation-menu';

import NavLinkItem, { TopLink } from '@components/NavLinkItem';

import { ChevronDownIcon } from '@radix-ui/react-icons';
import useTranslation from 'next-translate/useTranslation';

interface NavigationMenuProps {
  topLinks: TopLink[];
}

const LI_CLASS_NAMES = 'flex';

export default function NavigationMenuDesktop({
  topLinks,
}: NavigationMenuProps) {
  const { t } = useTranslation('common');
  const tOverview = t('overview');

  return (
    <RadixNavigationMenu.Root className='flex items-center xl:flex-grow'>
      <RadixNavigationMenu.List className='flex-grow absolute text-base xl:flex xl:static xl:h-auto xl:shadow-none xl:gap-6 text-dark-blue'>
        {topLinks?.length > 0
          ? topLinks.map(({ title, url, subLinks }) => {
              return typeof subLinks !== 'undefined' ? (
                <RadixNavigationMenu.Item key={title}>
                  <RadixNavigationMenu.Trigger>
                    {title}
                    <ChevronDownIcon className='hidden ml-1 pointer-events-none xl:inline text-interactive-blue' />
                  </RadixNavigationMenu.Trigger>
                  <RadixNavigationMenu.Content className='absolute rounded-br-md rounded-bl-md xl:border border-divider-light xl:rounded-md xl:shadow-lg overflow-auto max-h-[calc(100vh-(67px+4rem))] py-4 bg-white'>
                    <ul className='grid min-w-max xl:place-content-start'>
                      <li className={LI_CLASS_NAMES} key={url}>
                        <NavLinkItem title={tOverview} url={url} />
                      </li>
                      {subLinks.map(({ title, url }) => (
                        <li className={LI_CLASS_NAMES} key={url}>
                          <NavLinkItem title={title} url={url} />
                        </li>
                      ))}
                    </ul>
                  </RadixNavigationMenu.Content>
                </RadixNavigationMenu.Item>
              ) : (
                <RadixNavigationMenu.Item key={title}>
                  <NavLinkItem title={title} url={url} isTopLink={true} />
                </RadixNavigationMenu.Item>
              );
            })
          : null}
      </RadixNavigationMenu.List>
    </RadixNavigationMenu.Root>
  );
}
