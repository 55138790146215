export default function getCountryCode(country: string) {
  switch (country) {
    case 'Afghanistan': {
      return 'AF';
    }
    case 'Åland Islands': {
      return 'AX';
    }
    case 'Albania': {
      return 'AL';
    }
    case 'Algeria': {
      return 'DZ';
    }
    case 'American Samoa': {
      return 'AS';
    }
    case 'Andorra': {
      return 'AD';
    }
    case 'Angola': {
      return 'AO';
    }
    case 'Anguilla': {
      return 'AI';
    }
    case 'Antigua and Barbuda': {
      return 'AG';
    }
    case 'Argentina': {
      return 'AR';
    }
    case 'Armenia': {
      return 'AM';
    }
    case 'Aruba': {
      return 'AW';
    }
    case 'Australia': {
      return 'AU';
    }
    case 'Austria': {
      return 'AT';
    }
    case 'Azerbaijan': {
      return 'AZ';
    }
    case 'Bahamas': {
      return 'BS';
    }
    case 'Bahrain': {
      return 'BH';
    }
    case 'Bangladesh': {
      return 'BD';
    }
    case 'Barbados': {
      return 'BB';
    }
    case 'Belarus': {
      return 'BY';
    }
    case 'Belgium': {
      return 'BE';
    }
    case 'Belize': {
      return 'BZ';
    }
    case 'Benin': {
      return 'BJ';
    }
    case 'Bermuda': {
      return 'BM';
    }
    case 'Bhutan': {
      return 'BT';
    }
    case 'Bolivia': {
      return 'BO';
    }
    case 'Bosnia and Herzegovina': {
      return 'BA';
    }
    case 'Botswana': {
      return 'BW';
    }
    case 'Brazil': {
      return 'BR';
    }
    case 'British Indian Ocean Territory': {
      return 'IO';
    }
    case 'British Virgin Islands': {
      return 'VG';
    }
    case 'Brunei': {
      return 'BN';
    }
    case 'Bulgaria': {
      return 'BG';
    }
    case 'Burkina Faso': {
      return 'BF';
    }
    case 'Burundi': {
      return 'BI';
    }
    case 'Cambodia': {
      return 'KH';
    }
    case 'Cameroon': {
      return 'CM';
    }
    case 'Canada': {
      return 'CA';
    }
    case 'Cape Verde': {
      return 'CV';
    }
    case 'Caribbean Netherlands': {
      return 'BQ';
    }
    case 'Cayman Islands': {
      return 'KY';
    }
    case 'Central African Republic': {
      return 'CF';
    }
    case 'Chad': {
      return 'TD';
    }
    case 'Chile': {
      return 'CL';
    }
    case 'China': {
      return 'CN';
    }
    case 'Christmas Island': {
      return 'CX';
    }
    case 'Cocos [Keeling] Islands': {
      return 'CC';
    }
    case 'Colombia': {
      return 'CO';
    }
    case 'Comoros': {
      return 'KM';
    }
    case 'Democratic Republic Congo': {
      return 'CD';
    }
    case 'Republic of Congo': {
      return 'CG';
    }
    case 'Cook Islands': {
      return 'CK';
    }
    case 'Costa Rica': {
      return 'CR';
    }
    case "Côte d'Ivoire": {
      return 'CI';
    }
    case 'Croatia': {
      return 'HR';
    }
    case 'Cuba': {
      return 'CU';
    }
    case 'Curaçao': {
      return 'CW';
    }
    case 'Cyprus': {
      return 'CY';
    }
    case 'Czech Republic': {
      return 'CZ';
    }
    case 'Denmark': {
      return 'DK';
    }
    case 'Djibouti': {
      return 'DJ';
    }
    case 'Dominica': {
      return 'DM';
    }
    case 'Dominican Republic': {
      return 'DO';
    }
    case 'East Timor': {
      return 'TL';
    }
    case 'Ecuador': {
      return 'EC';
    }
    case 'Egypt': {
      return 'EG';
    }
    case 'El Salvador': {
      return 'SV';
    }
    case 'Equatorial Guinea': {
      return 'GQ';
    }
    case 'Eritrea': {
      return 'ER';
    }
    case 'Estonia': {
      return 'EE';
    }
    case 'Ethiopia': {
      return 'ET';
    }
    case 'Falkland Islands [Islas Malvinas]': {
      return 'FK';
    }
    case 'Faroe Islands': {
      return 'FO';
    }
    case 'Fiji': {
      return 'FJ';
    }
    case 'Finland': {
      return 'FI';
    }
    case 'France': {
      return 'FR';
    }
    case 'French Guiana': {
      return 'GF';
    }
    case 'French Polynesia': {
      return 'PF';
    }
    case 'Gabon': {
      return 'GA';
    }
    case 'Gambia': {
      return 'GM';
    }
    case 'Georgia': {
      return 'GE';
    }
    case 'Germany': {
      return 'DE';
    }
    case 'Ghana': {
      return 'GH';
    }
    case 'Gibraltar': {
      return 'GI';
    }
    case 'Greece': {
      return 'GR';
    }
    case 'Greenland': {
      return 'GL';
    }
    case 'Grenada': {
      return 'GD';
    }
    case 'Guadeloupe': {
      return 'GP';
    }
    case 'Guam': {
      return 'GU';
    }
    case 'Guatemala': {
      return 'GT';
    }
    case 'Guernsey': {
      return 'GG';
    }
    case 'Guinea Conakry': {
      return 'GN';
    }
    case 'Guinea-Bissau': {
      return 'GW';
    }
    case 'Guyana': {
      return 'GY';
    }
    case 'Haiti': {
      return 'HT';
    }
    case 'Heard Island and McDonald Islands': {
      return 'HM';
    }
    case 'Honduras': {
      return 'HN';
    }
    case 'Hong Kong': {
      return 'HK';
    }
    case 'Hungary': {
      return 'HU';
    }
    case 'Iceland': {
      return 'IS';
    }
    case 'India': {
      return 'IN';
    }
    case 'Indonesia': {
      return 'ID';
    }
    case 'Iran': {
      return 'IR';
    }
    case 'Iraq': {
      return 'IQ';
    }
    case 'Ireland': {
      return 'IE';
    }
    case 'Isle of Man': {
      return 'IM';
    }
    case 'Israel': {
      return 'IL';
    }
    case 'Italy': {
      return 'IT';
    }
    case 'Jamaica': {
      return 'JM';
    }
    case 'Japan': {
      return 'JP';
    }
    case 'Jersey': {
      return 'JE';
    }
    case 'Jordan': {
      return 'JO';
    }
    case 'Kazakhstan': {
      return 'KZ';
    }
    case 'Kenya': {
      return 'KE';
    }
    case 'Kiribati': {
      return 'KI';
    }
    case 'Kuwait': {
      return 'KW';
    }
    case 'Kyrgyzstan': {
      return 'KG';
    }
    case 'Laos': {
      return 'LA';
    }
    case 'Latvia': {
      return 'LV';
    }
    case 'Lebanon': {
      return 'LB';
    }
    case 'Lesotho': {
      return 'LS';
    }
    case 'Liberia': {
      return 'LR';
    }
    case 'Libya': {
      return 'LY';
    }
    case 'Liechtenstein': {
      return 'LI';
    }
    case 'Lithuania': {
      return 'LT';
    }
    case 'Luxembourg': {
      return 'LU';
    }
    case 'Macau': {
      return 'MO';
    }
    case 'Macedonia': {
      return 'MK';
    }
    case 'Madagascar': {
      return 'MG';
    }
    case 'Malawi': {
      return 'MW';
    }
    case 'Malaysia': {
      return 'MY';
    }
    case 'Maldives': {
      return 'MV';
    }
    case 'Mali': {
      return 'ML';
    }
    case 'Malta': {
      return 'MT';
    }
    case 'Marshall Islands': {
      return 'MH';
    }
    case 'Martinique': {
      return 'MQ';
    }
    case 'Mauritania': {
      return 'MR';
    }
    case 'Mauritius': {
      return 'MU';
    }
    case 'Mayotte': {
      return 'YT';
    }
    case 'Mexico': {
      return 'MX';
    }
    case 'Micronesia': {
      return 'FM';
    }
    case 'Moldova': {
      return 'MD';
    }
    case 'Monaco': {
      return 'MC';
    }
    case 'Mongolia': {
      return 'MN';
    }
    case 'Montenegro': {
      return 'ME';
    }
    case 'Montserrat': {
      return 'MS';
    }
    case 'Morocco': {
      return 'MA';
    }
    case 'Mozambique': {
      return 'MZ';
    }
    case 'Myanmar [Burma]': {
      return 'MM';
    }
    case 'Namibia': {
      return 'NA';
    }
    case 'Nauru': {
      return 'NR';
    }
    case 'Nepal': {
      return 'NP';
    }
    case 'Netherlands': {
      return 'NL';
    }
    case 'New Caledonia': {
      return 'NC';
    }
    case 'New Zealand': {
      return 'NZ';
    }
    case 'Nicaragua': {
      return 'NI';
    }
    case 'Niger': {
      return 'NE';
    }
    case 'Nigeria': {
      return 'NG';
    }
    case 'Niue': {
      return 'NU';
    }
    case 'Norfolk Island': {
      return 'NF';
    }
    case 'North Korea': {
      return 'KP';
    }
    case 'Northern Mariana Islands': {
      return 'MP';
    }
    case 'Norway': {
      return 'NO';
    }
    case 'Oman': {
      return 'OM';
    }
    case 'Pakistan': {
      return 'PK';
    }
    case 'Palau': {
      return 'PW';
    }
    case 'Palestinian Territories': {
      return 'PS';
    }
    case 'Panama': {
      return 'PA';
    }
    case 'Papua New Guinea': {
      return 'PG';
    }
    case 'Paraguay': {
      return 'PY';
    }
    case 'Peru': {
      return 'PE';
    }
    case 'Philippines': {
      return 'PH';
    }
    case 'Poland': {
      return 'PL';
    }
    case 'Portugal': {
      return 'PT';
    }
    case 'Puerto Rico': {
      return 'PR';
    }
    case 'Qatar': {
      return 'QA';
    }
    case 'Réunion': {
      return 'RE';
    }
    case 'Romania': {
      return 'RO';
    }
    case 'Russia': {
      return 'RU';
    }
    case 'Rwanda': {
      return 'RW';
    }
    case 'Saint Barthélemy': {
      return 'BL';
    }
    case 'Saint Helena': {
      return 'SH';
    }
    case 'St. Kitts': {
      return 'KN';
    }
    case 'St. Lucia': {
      return 'LC';
    }
    case 'Saint Martin': {
      return 'MF';
    }
    case 'Saint Pierre and Miquelon': {
      return 'PM';
    }
    case 'St. Vincent': {
      return 'VC';
    }
    case 'Samoa': {
      return 'WS';
    }
    case 'San Marino': {
      return 'SM';
    }
    case 'São Tomé and Príncipe': {
      return 'ST';
    }
    case 'Saudi Arabia': {
      return 'SA';
    }
    case 'Senegal': {
      return 'SN';
    }
    case 'Serbia': {
      return 'RS';
    }
    case 'Seychelles': {
      return 'SC';
    }
    case 'Sierra Leone': {
      return 'SL';
    }
    case 'Singapore': {
      return 'SG';
    }
    case 'Sint Maarten': {
      return 'SX';
    }
    case 'Slovakia': {
      return 'SK';
    }
    case 'Slovenia': {
      return 'SI';
    }
    case 'Solomon Islands': {
      return 'SB';
    }
    case 'Somalia': {
      return 'SO';
    }
    case 'South Africa': {
      return 'ZA';
    }
    case 'South Georgia and the South Sandwich Islands': {
      return 'GS';
    }
    case 'South Korea': {
      return 'KR';
    }
    case 'South Sudan': {
      return 'SS';
    }
    case 'Spain': {
      return 'ES';
    }
    case 'Sri Lanka': {
      return 'LK';
    }
    case 'Sudan': {
      return 'SD';
    }
    case 'Suriname': {
      return 'SR';
    }
    case 'Svalbard and Jan Mayen': {
      return 'SJ';
    }
    case 'Swaziland': {
      return 'SZ';
    }
    case 'Sweden': {
      return 'SE';
    }
    case 'Switzerland': {
      return 'CH';
    }
    case 'Syria': {
      return 'SY';
    }
    case 'Taiwan': {
      return 'TW';
    }
    case 'Tajikistan': {
      return 'TJ';
    }
    case 'Tanzania': {
      return 'TZ';
    }
    case 'Thailand': {
      return 'TH';
    }
    case 'Togo': {
      return 'TG';
    }
    case 'Tokelau': {
      return 'TK';
    }
    case 'Tonga': {
      return 'TO';
    }
    case 'Trinidad/Tobago': {
      return 'TT';
    }
    case 'Tunisia': {
      return 'TN';
    }
    case 'Turkey': {
      return 'TR';
    }
    case 'Turkmenistan': {
      return 'TM';
    }
    case 'Turks and Caicos Islands': {
      return 'TC';
    }
    case 'Tuvalu': {
      return 'TV';
    }
    case 'U.S. Virgin Islands': {
      return 'VI';
    }
    case 'Uganda': {
      return 'UG';
    }
    case 'Ukraine': {
      return 'UA';
    }
    case 'United Arab Emirates': {
      return 'AE';
    }
    case 'United Kingdom': {
      return 'GB';
    }
    case 'United States': {
      return 'US';
    }
    case 'Uruguay': {
      return 'UY';
    }
    case 'Uzbekistan': {
      return 'UZ';
    }
    case 'Vanuatu': {
      return 'VU';
    }
    case 'Vatican City': {
      return 'VA';
    }
    case 'Venezuela': {
      return 'VE';
    }
    case 'Vietnam': {
      return 'VN';
    }
    case 'Wallis and Futuna': {
      return 'WF';
    }
    case 'Western Sahara': {
      return 'EH';
    }
    case 'Yemen': {
      return 'YE';
    }
    case 'Zambia': {
      return 'ZM';
    }
    case 'Zimbabwe': {
      return 'ZW';
    }
  }
}
