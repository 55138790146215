import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { DotFilledIcon, GlobeIcon } from '@radix-ui/react-icons';

import { LOCALE } from '@lib/types';
import Portal from '@components/Portal';
import { localeToLanguage } from '@lib/utils';
import { useRouter } from 'next/router';
import { useState } from 'react';

interface LanguagePickerProps {
  locale: LOCALE;
  locales: LOCALE[];
}

export default function LanguagePicker({
  locale,
  locales,
}: LanguagePickerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();

  return (
    <>
      <Portal targetId='portal-target-backdrop'>
        <button
          className={
            isOpen
              ? 'absolute z-10 top-0 left-0 w-full h-full bg-black opacity-80 md:hidden'
              : 'hidden'
          }
          aria-label='Close language menu'
          onClick={() => setIsOpen(false)}
        ></button>
      </Portal>
      <div className='md:relative'>
        <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen}>
          <DropdownMenu.Trigger asChild>
            <button
              className='flex items-center justify-center cursor-pointer text-dark-blue hover:text-turquoise'
              aria-label='Select language'
            >
              <GlobeIcon className='h-[18px] w-[18px]' />
              <span className='hidden pl-1 text-sm uppercase md:inline-block'>
                {locale}
              </span>
            </button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content
              align='end'
              sideOffset={5}
              loop
              className='right-0 w-screen pt-3 pb-2 text-sm bg-white border-t border-b rounded-br-md rounded-bl-md md:w-auto md:min-h-max md:shadow-lg md:border md:rounded-md md:border-t md:fixed md:top-full border-divider-light'
            >
              <DropdownMenu.Label className='px-6 mb-2 md:pb-2 md:border-b text-graphite border-bottom-divider-light'>
                Language
              </DropdownMenu.Label>
              <DropdownMenu.RadioGroup value={locale}>
                {locales.map((locale) => (
                  <DropdownMenu.RadioItem
                    value={locale}
                    key={locale}
                    className='relative flex items-center px-6 py-2 cursor-pointer hover:bg-divider-light'
                    onSelect={() =>
                      router.push(
                        locale === 'sv' ? '/' : `/${locale}`,
                        locale === 'sv' ? '/' : `/${locale}`,
                        {
                          locale: locale,
                        }
                      )
                    }
                  >
                    <DropdownMenu.ItemIndicator className='absolute left-1'>
                      <DotFilledIcon />
                    </DropdownMenu.ItemIndicator>
                    <div className='flex items-baseline text-dark-blue'>
                      {localeToLanguage(locale)}&nbsp;
                      <span className='uppercase'>{`(${locale})`}</span>
                    </div>
                  </DropdownMenu.RadioItem>
                ))}
              </DropdownMenu.RadioGroup>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </div>
    </>
  );
}
