import { useCallback, useState } from 'react';

import { HamburgerMenuIcon } from '@radix-ui/react-icons';
import NavigationMenuDesktop from '@components/NavigationMenuDesktop';
import NavigationMenuMobile from '@components/NavigationMenuMobile';
import Portal from '@components/Portal';
import { TopLink } from '@components/NavLinkItem';

interface NavigationMenuProps {
  topLinks: TopLink[];
}

export default function NavigationMenu({ topLinks }: NavigationMenuProps) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const handleSetIsMobileMenuOpen = useCallback((newValue: boolean) => {
    setIsMobileMenuOpen(newValue);
  }, []);

  return (
    <>
      {/* <Portal targetId='portal-target-backdrop'>
        <button
          className={
            isMobileMenuOpen
              ? 'absolute z-10 top-0 left-0 w-full h-full bg-black opacity-80 xl:hidden'
              : 'hidden'
          }
          aria-label='Close navigation menu'
          onClick={() => setIsMobileMenuOpen(false)}
        ></button>
      </Portal> */}
      <div className='hidden xl:block'>
        <NavigationMenuDesktop topLinks={topLinks} />
      </div>
      <div className='xl:hidden'>
        {/* <button
          className='flex items-center justify-center cursor-pointer text-dark-blue hover:text-turquoise'
          onClick={() => setIsMobileMenuOpen((val) => !val)}
          aria-label={`${isMobileMenuOpen ? 'Close' : 'Open'} navigation menu`}
        >
          <HamburgerMenuIcon className='h-[22px] w-[22px]' />
        </button> */}
        <NavigationMenuMobile
          topLinks={topLinks}
          isOpen={isMobileMenuOpen}
          handleSetIsMobileMenuOpen={handleSetIsMobileMenuOpen}
        />
      </div>
    </>
  );
}
