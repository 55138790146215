import Link from 'next/link';
import { connectHits } from 'react-instantsearch-dom';
import useTranslation from 'next-translate/useTranslation';

function hitPicker(hit, locale, t) {
  const { fields, metadata, objectID, sys } = hit;
  const type = sys?.contentType?.sys?.id;

  if (!type) return null;

  switch (type) {
    case 'article': {
      const { category, image, preamble, publicationDate, slug, title } =
        fields;
      const tArticle = t('articles:article');
      const tArticles = t('articles:articles');

      return (
        <Link
          href={`/${tArticles}/${slug[locale]}`}
          key={objectID}
          className='block pb-4 mb-4 border-b border-divider-light last:border-0'
        >
          {/* <p className='text-xs uppercase text-turquoise'>{`${tArticle} | ${tCategory}`}</p> */}
          <p className='text-xs uppercase text-turquoise'>{tArticle}</p>
          <h3 className='text-xl'>{title[locale]}</h3>
          <p>{preamble[locale]}</p>
        </Link>
      );
    }
    case 'page': {
      const { slug, pageName: pageNames } = fields;
      const pageName = pageNames[locale];
      const tHitType = t('search:hit-type-page');

      return (
        <Link
          href={`/${slug[locale]}`}
          className='block pb-4 mb-4 border-b border-divider-light last:border-0'
          key={objectID}
        >
          <p className='text-xs uppercase text-turquoise'>{tHitType}</p>
          <h3 className='text-xl'>{pageName}</h3>
        </Link>
      );
    }
    case 'subLandingPage': {
      const { slug, pageName: pageNames, parentSlug: parentSlugs } = fields;
      const pageName = pageNames[locale];
      const parentSlug = parentSlugs[locale];
      const tHitType = t('search:hit-type-page');

      return (
        <Link
          href={`/${parentSlug}/${slug[locale]}`}
          className='block pb-4 mb-4 border-b border-divider-light last:border-0'
          key={objectID}
        >
          <p className='text-xs uppercase text-turquoise'>{tHitType}</p>
          <h3 className='text-xl'>{pageName}</h3>
        </Link>
      );
    }
    default:
      console.log('No template for this search hit:', hit);
      return null;
  }
}

const Hit = ({ hits, locale }) => {
  const { t } = useTranslation();

  return <ol>{hits.map((hit) => hitPicker(hit, locale, t))}</ol>;
};

const SearchHits = connectHits(Hit);

export default SearchHits;
