import ArticleListItem from '@components/ArticleListItem';
import useTranslation from 'next-translate/useTranslation';

export default function ArticleList({ articles }) {
  const { t } = useTranslation('common');
  const tReadMore = t('read-more');

  return (
    <aside>
      <h6 className='first-letter:uppercase'>{tReadMore}</h6>
      <ol className='grid gap-4 mt-4'>
        {articles.map((article) => (
          <ArticleListItem
            category={t(`articles:category-${article.category}`)}
            title={article.title}
            slug={article.slug}
            publicationDate={article.publicationDate}
            key={article.slug}
          />
        ))}
      </ol>
    </aside>
  );
}
