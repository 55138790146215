import { useEffect, useState } from 'react';

import { LOCALE } from '@lib/types';
import LanguagePicker from '@components/LanguagePicker';
import Link from 'next/link';
import NavigationMenu from '@components/NavigationMenu';
import SearchPopover from '@components/SearchPopover';
import { TopLink } from '@components/NavLinkItem';

interface HeaderProps {
  locale: LOCALE;
  locales: LOCALE[];
  topLinks: TopLink[];
}

export default function HeaderComponent(props: HeaderProps) {
  const { locale, locales, topLinks } = props;
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const onScroll = (e) => {
      setIsScrolling(e.target.documentElement.scrollTop > 0);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [setIsScrolling]);

  return (
    <header
      className={`flex w-screen md:w-full content-center py-4 sticky top-0 bg-white z-20 border-b border-divider-light${
        isScrolling ? ' md:border-t-0 md:shadow-md md:border-b-0' : ''
      }`}
    >
      <div className='container flex items-center justify-between gap-2 px-5 mx-auto md:relative md:gap-6'>
        <Link href='/' className='w-[250px] max-w-[50%]' aria-label='Home'>
          {/* eslint-disable-next-line */}
          <img
            src='/images/vat_adviser_logo.svg'
            className='object-contain'
            alt='The VAT Adviser logo'
            width={250}
          />
        </Link>
        <div className='flex flex-row-reverse items-center justify-between gap-6 xl:flex-row'>
          <NavigationMenu topLinks={topLinks} />
          <LanguagePicker locale={locale} locales={locales} />
          <SearchPopover />
        </div>
      </div>
    </header>
  );
}
