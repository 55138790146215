import Container from '@components/Container';
import ContentfulImage from '@components/ContentfulImage';
import { IContentfulImage } from '@lib/types';

export interface IFooterProps {
  image: IContentfulImage;
  logo: IContentfulImage;
  slogan: string;
  copyrightAndAdress: string;
  children: JSX.Element;
}

export default function Footer({
  image,
  logo,
  slogan,
  copyrightAndAddress,
  children,
}) {
  return (
    <footer className='text-white bg-dark-blue prose-headings:text-white prose-a:text-white prose-p:text-white prose-p:text-base'>
      <Container className='relative pb-16'>
        {image?.url ? (
          <div className='absolute -translate-y-1/2 w-28 h-28'>
            <ContentfulImage
              src={image.url}
              alt={image?.description}
              width={220}
              height={220}
            />
          </div>
        ) : null}
        <div className='grid gap-12 py-16 md:grid-cols-4 place-items-start lg:flex-row'>
          {children}
        </div>
        <div className='grid gap-4 md:grid-cols-[220px_160px] md:place-items-center'>
          {logo?.url ? (
            <div className='relative flex h-12 md:mx-auto md:items-center w-52'>
              <ContentfulImage
                src={logo.url}
                alt={logo?.description}
                width={220}
                height={41}
              />
            </div>
          ) : null}
          <p>{slogan}</p>
        </div>
      </Container>
      <div className='bg-white text-center border-t-[10px] border-turquoise p-16 prose-p:text-graphite prose-p:text-sm'>
        <Container>{copyrightAndAddress}</Container>
      </div>
    </footer>
  );
}
