import * as NavigationMenu from '@radix-ui/react-navigation-menu';

import { ChevronDownIcon, DotFilledIcon } from '@radix-ui/react-icons';

import Link from 'next/link';
import React from 'react';
import { SubLink } from './SubLinkItem';
import { useRouter } from 'next/router';

export type TopLink = {
  title: string;
  url: string;
  subLinks?: SubLink[];
};

interface INavLinkItemProps extends TopLink, SubLink {
  isTopLink?: boolean;
}

const NavLinkItem = React.forwardRef(
  (props: INavLinkItemProps, forwardedRef: any) => {
    const { title, url, isTopLink, ...rest } = props;
    // const [isActive, setIsActive] = React.useState(false);
    // const router = useRouter();

    // React.useEffect(() => {
    //   if (router && router.asPath) {
    //     setIsActive(decodeURIComponent(router.asPath) === url);
    //   }
    // }, [router, url]);

    return (
      <Link href={url} passHref legacyBehavior={true}>
        <NavigationMenu.Link
          // active={isActive}
          {...rest}
          ref={forwardedRef}
          className={`flex-1 relative focus:bg-divider-light first-letter:uppercase ${
            isTopLink ? '' : 'hover:bg-divider-light p-3'
          }`}
        >
          {/* {isActive ? (
            <DotFilledIcon className='absolute justify-self-center -left-4' />
          ) : null} */}
          {title}
        </NavigationMenu.Link>
      </Link>
    );
  }
);
NavLinkItem.displayName = 'NavLinkItem';

export default NavLinkItem;
