import { Cross2Icon, MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { Hits, InstantSearch, SearchBox, Stats } from 'react-instantsearch-dom';
import { useEffect, useState } from 'react';

import LoadingIndicator from '@components/LoadingIndicator';
import SearchHits from '@components/SearchHits';
import SearchStats from '@components/SearchStats';
import algoliasearch from 'algoliasearch/lite';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY
);

export default function Search({ children }) {
  const { t } = useTranslation('search');
  const router = useRouter();
  const [query, setQuery] = useState('');
  const tTitle = t('title');

  useEffect(() => {
    if (!router.isReady) return;

    if (router.query?.q) setQuery(router.query?.q as any);
    // eslint-disable-next-line
  }, [router.isReady]);

  if (!router?.isReady) return <LoadingIndicator />;

  // pb-5 border-b border-b-didiver-light last:border-0

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName='universal'
      searchState={{
        query: query,
      }}
    >
      <h2 className='mb-4 text-2xl md:text-3xl'>{tTitle}</h2>
      <div className='relative max-w-lg'>
        <SearchBox
          autoFocus
          // reset={<Cross2Icon />}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          submit={<MagnifyingGlassIcon />}
        />
      </div>
      <div className='grid lg:grid-cols-[1fr,300px] gap-24 mt-8'>
        <div>
          <SearchStats />
          <SearchHits locale={router.locale} />
        </div>
        {children}
      </div>
    </InstantSearch>
  );
}
