import { format } from 'date-fns';

interface DateProps {
  dateString: string;
  prefix?: string;
  suffix?: string;
}

export default function DateComponent({
  dateString,
  prefix,
  suffix,
}: DateProps) {
  return (
    <time dateTime={dateString} className='text-sm text-light-gray'>
      {typeof prefix !== undefined ? (
        <span className='inline-block first-letter:uppercase'>
          {prefix}&nbsp;
        </span>
      ) : null}
      {format(new Date(dateString), 'd LLLL, yyyy')}
      {typeof suffix !== undefined ? suffix : null}
    </time>
  );
}
