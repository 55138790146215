import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { IContentfulImage, PageTypeSpecificData } from '@lib/types';

import Article from '@components/Article';
import ArticleList from '@components/ArticleList';
import ArticleTeaser from '@components/ArticleTeaser';
import ContactForm from '@components/ContactForm';
import ContentfulImage from '@components/ContentfulImage';
import Footer from '@components/Footer';
import Hero from '@components/Hero';
import Image from 'next/image';
import Link from 'next/link';
import RichTextWrapper from '@components/RichTextWrapper';
import Search from '@components/Search';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import getCountryCode from '@lib/utils/getCountryCode';

export const richTextOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      const isEmptyNode = children?.toString().trim() === '';

      if (isEmptyNode) return null;

      return <p>{children}</p>;
    },
    [INLINES.HYPERLINK]: ({ data }, children) => {
      const usesAnchorTag = Boolean(data?.uri?.match(/http|www|mailto:|tel:/i));
      const isExternalLink = Boolean(data?.uri?.match(/http|www/i));
      const externalLinkProps = {
        target: isExternalLink ? '_blank' : undefined,
        rel: isExternalLink ? 'noopener noreferrer' : undefined,
      };

      return usesAnchorTag ? (
        <a href={data.uri} {...externalLinkProps}>
          {children}
        </a>
      ) : (
        <Link href={`/${data.uri}`}>{children}</Link>
      );
    },
  },
};

export function transformContentModel(
  contentObject,
  t?: Function
): JSX.Element {
  if (!contentObject?.__typename) return null;

  // Typename's are unique to the content model
  // Id's are unique to an instance of the content model
  const {
    __typename,
    sys: { id },
  } = contentObject;

  switch (__typename) {
    case 'HeroTextWithServiceLinks': {
      const { primaryTextField, serviceLinks, image, circularImage } =
        contentObject;

      return (
        <RichTextWrapper
          className='relative grid gap-10 lg:gap-28 lg:grid-cols-5 prose-a:text-interactive-blue prose-p:mt-1 prose-h2:mt-0 prose-h2:mb-2 prose-h4:mb-0 !max-w-full'
          key={id}
        >
          <div className='lg:col-span-3'>
            {primaryTextField?.content?.json
              ? documentToReactComponents(
                  primaryTextField.content.json,
                  richTextOptions
                )
              : null}
            {serviceLinks?.title?.json
              ? documentToReactComponents(
                  serviceLinks.title.json,
                  richTextOptions
                )
              : null}
            <div className='grid gap-x-10 gap-y-5 lg:grid-cols-2 prose-headings:mt-0 prose-p:mb-0'>
              {serviceLinks?.serviceLinksCollection?.items?.flatMap(
                (item, i) => {
                  return item?.content?.json
                    ? [
                        <div key={item?.sys?.id ?? i}>
                          {documentToReactComponents(
                            item.content.json,
                            richTextOptions
                          )}
                        </div>,
                      ]
                    : [];
                }
              ) ?? null}
            </div>
          </div>
          {image?.url ? (
            <div
              className={`relative hidden w-full md:block lg:col-span-2 lg:prose-img:mt-2 prose-img:mb-0 prose-img:absolute prose-img:right-0${
                circularImage
                  ? ' lg:rounded-full lg:overflow-hidden lg:aspect-square'
                  : ''
              }`}
            >
              <ContentfulImage
                src={image.url}
                alt={image?.description}
                sizes='50vw'
                objectFit={circularImage ? 'cover' : 'contain'}
                objectPosition='top'
                priority
                layout='fill'
              />
            </div>
          ) : null}
        </RichTextWrapper>
      );
    }
    case 'ContentTypeRichText': {
      const { content } = contentObject;

      return content?.json ? (
        <RichTextWrapper key={id}>
          {documentToReactComponents(content.json, richTextOptions)}
        </RichTextWrapper>
      ) : null;
    }
    case 'CallToActionBox': {
      const content = contentObject?.content?.content;

      return content?.json ? (
        <aside className='p-8 border border-divider-dark' key={id}>
          <RichTextWrapper className='prose-a:text-interactive-blue' key={id}>
            {documentToReactComponents(content.json, richTextOptions)}
          </RichTextWrapper>
        </aside>
      ) : null;
    }
    case 'Hero': {
      const { title, subtitle, image, fullWidth, bow, low, gradient } =
        contentObject;

      return (
        <Hero
          title={title}
          subtitle={subtitle}
          image={image}
          fullWidth={fullWidth}
          bow={bow}
          low={low}
          gradient={gradient}
          key={id}
        />
      );
    }
    case 'Article': {
      const { recentArticles, ...rest } = contentObject;

      return <Article {...rest} recentArticles={recentArticles} key={id} />;
    }
    case 'Table': {
      const { tableJson, title, source } = contentObject;

      if (!tableJson) return null;

      const [header, ...rows] = tableJson;
      const headerMarkup = header?.map((cell, i) => (
        <th
          className='pb-4 pr-2 text-left md:pr-6 last:pr-0'
          key={'header' + i}
        >
          {cell}
        </th>
      ));
      const rowMarkup = rows?.map((row, i) => {
        return (
          <tr key={'row' + i} className='border-b border-divider-light'>
            {row.map((cell, j) => (
              <td
                className='py-2 pr-2 md:pr-6 last:pr-0 first:pt-4'
                key={'cell' + i + j}
              >
                {cell}
              </td>
            ))}
          </tr>
        );
      });

      return (
        <div className='mt-2' key={id}>
          {title?.json ? (
            <RichTextWrapper>
              {documentToReactComponents(title.json, richTextOptions)}
            </RichTextWrapper>
          ) : null}
          <table className='w-full mt-4 table-auto'>
            <thead className='border-b text-dark-blue border-divider-light'>
              <tr>{headerMarkup}</tr>
            </thead>
            <tbody>{rowMarkup}</tbody>
          </table>
          {source ? <p className='text-sm'>{source}</p> : null}
        </div>
      );
    }
    case 'FlagLink': {
      const { country } = contentObject;
      const slug = contentObject?.linksTo?.slug;
      const countryCode = getCountryCode(country);
      const parentSlug = contentObject?.linksTo?.parentSlug;
      const commonWrapperClasses =
        'flex gap-4 place-items-center text-dark-blue text-sm';
      const commonTextClasses = 'break-all';
      const tCountry = t(`countries:${countryCode}`);
      const tFlagOf = t('common:flag-of', {
        country: tCountry,
      });

      if (!slug && !parentSlug)
        return (
          <div className={commonWrapperClasses} key={id}>
            <Image
              unoptimized
              src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.4/flags/4x3/${getCountryCode(
                country
              ).toLowerCase()}.svg`}
              alt={tFlagOf}
              width={35}
              height={35}
            />
            <p className={commonTextClasses}>{tCountry}</p>
          </div>
        );
      const href = `/${parentSlug ? parentSlug + '/' : ''}${slug}`;

      return (
        <Link href={href} className={commonWrapperClasses} key={id}>
          <Image
            unoptimized
            src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.4/flags/4x3/${getCountryCode(
              country
            ).toLowerCase()}.svg`}
            alt={tFlagOf}
            width={35}
            height={35}
          />
          <p className={commonTextClasses + ' underline underline-offset-1'}>
            {tCountry}
          </p>
        </Link>
      );
    }
    case 'FlagLinks': {
      const { flagLinksCollection } = contentObject;
      const flagLinksData = flagLinksCollection?.items ?? [];

      return (
        <div
          className='grid flex-wrap grid-cols-2 gap-8 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5'
          key={id}
        >
          {flagLinksData.map((flagLink) => transformContentModel(flagLink, t))}
        </div>
      );
    }
    case 'FooterLink': {
      const { linkText, slug, external: isExternal } = contentObject;

      return isExternal ? (
        <a href={slug} key={id}>
          {linkText}
        </a>
      ) : (
        <Link href={`/${slug}`} key={id}>
          {linkText}
        </Link>
      );
    }
    case 'FooterGroup': {
      const { contentCollection, title } = contentObject;
      const footerGroupData = contentCollection?.items ?? [];

      return (
        <section key={id}>
          {/* We use H1's here because the section element creates a new headline context */}
          {title ? <h1 className='mb-4 text-xl text-white'>{title}</h1> : null}
          <div className='grid gap-2 prose-a:text-white'>
            {footerGroupData.map((content) =>
              transformContentModel(content, t)
            )}
          </div>
        </section>
      );
    }
    case 'Footer': {
      const {
        footerGroupsCollection,
        image,
        logo,
        slogan,
        copyrightAndAddress,
      } = contentObject;
      const footerGroups = footerGroupsCollection?.items ?? [];
      const transformedCopyrightAndAddress = documentToReactComponents(
        copyrightAndAddress?.json,
        richTextOptions
      );

      return (
        <Footer
          image={image}
          logo={logo}
          slogan={slogan}
          copyrightAndAddress={transformedCopyrightAndAddress}
          key={id}
        >
          {footerGroups.map((footerGroup) =>
            transformContentModel(footerGroup, t)
          )}
        </Footer>
      );
    }
    default:
      // TODO Log unhandeled fields
      console.log('No case for', contentObject);
      return null;
  }
}

export function transformPageTypeSpecificData(
  pageTypeSpecificData: PageTypeSpecificData,
  t: Function
): JSX.Element {
  if (!pageTypeSpecificData) return null;

  const { pageType } = pageTypeSpecificData;

  switch (pageType) {
    case 'articles': {
      const { recentArticles } = pageTypeSpecificData;

      return recentArticles?.length ? (
        <div className='grid gap-8 md:flex'>
          {recentArticles.map(
            ({ title, publicationDate, slug, preamble, category, image }) => (
              <ArticleTeaser
                title={title}
                publicationDate={publicationDate}
                slug={slug}
                preamble={preamble}
                category={category}
                image={image}
                imagePriority={true}
                key={slug}
              />
            )
          )}
        </div>
      ) : null;
    }
    case 'search': {
      const { recentArticles } = pageTypeSpecificData;

      return recentArticles?.length ? (
        <Search>
          <ArticleList articles={recentArticles} />
        </Search>
      ) : null;
    }
    case 'contact': {
      return <ContactForm t={t} />;
    }
    default:
      // TODO Log unhandled fields
      return null;
  }
}
