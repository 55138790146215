export default function Bow() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100.1%'
      viewBox='0 0 1600 68.88'
      className='absolute bottom-0 z-10'
    >
      <path
        fill='var(--white)'
        d='M1600 0v68.88H0V0q400 68.88 800 68.88T1600 0z'
      />
      <rect y='68.5' width='100%' height='2' fill='var(--white)' />
    </svg>
  );
}
