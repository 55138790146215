const gql = String.raw; // Improves formatting in VS Code

export const ROOT_PAGE_QUERY = gql`
pageName
seo {
    __typename
    sys {
      id
    }
  title
  description
  image {
    url
    description
  }
  keywords
  noIndex
  noFollow
}
topSection {
    __typename
    sys {
      id
    }
  title
  subtitle
  image {
    url
    description
  }
  fullWidth
  bow
  low
  gradient
}
pageContentCollection {
  items {
    __typename
    sys {
      id
    }
    ... on ContentTypeRichText {
      content {
        json
      }
    }
    ... on HeroTextWithServiceLinks {
      circularImage
      image {
          url
          description
      }
      primaryTextField {
        content {
          json
        }
      }
      serviceLinks {
        title {
          json
        }
        serviceLinksCollection {
          items {
            sys {
              id
            }
            content {
              json
            }
          }
        }
      }
    }
    ... on FlagLinks {
      flagLinksCollection(limit: 35) {
        items {
          __typename
          sys {
            id
          }
          country
          linksTo {
            ... on Page {
              slug
            }
            ... on SubLandingPage {
              slug
              parentSlug
            }
          }
        }
      }
    }
  }
}
`;

export const LANDING_PAGE_QUERY = gql`
pageName
seo {
  __typename
    sys {
      id
    }
  title
  description
  image {
    url
    description
  }
  keywords
  noIndex
  noFollow
}
topSection {
  __typename
    sys {
      id
    }
  title
  subtitle
  image {
    url
    description
  }
  fullWidth
  bow
  low
  gradient
}
pageContentCollection {
  items {
    __typename
    sys {
      id
    }
    ... on ContentTypeRichText {
      content {
        json
      }
    }
    ... on CallToActionBox {
      content {
        ... on ContentTypeRichText {
      content {
        json
      }
    }
      }
    }
    ... on FlagLinks {
      flagLinksCollection(limit: 35) {
        items {
          __typename
          sys {
            id
          }
          country
          linksTo {
            ... on Page {
              slug
            }
            ... on SubLandingPage {
              slug
              parentSlug
            }
          }
        }
      }
    }
    ... on Table {
      title {
        json
      }
      source
      tableJson
    }
    ... on HeroTextWithServiceLinks {
      circularImage
      image {
          url
          description
      }
      primaryTextField {
        content {
          json
        }
      }
      serviceLinks {
        title {
          json
        }
        serviceLinksCollection {
          items {
            sys {
              id
            }
            content {
              json
            }
          }
        }
      }
    }
  }
}
`;

export const SUB_LANDING_PAGE_QUERY = gql`
pageName
seo {
  title
  description
  image {
    url
    description
  }
  keywords
  noIndex
  noFollow
}
topSection {
  __typename
    sys {
      id
    }
  title
  subtitle
  image {
    url
    description
  }
  fullWidth
  bow
  low
  gradient
}
pageContentCollection {
  items {
    __typename
    sys {
      id
    }
    ... on ContentTypeRichText {
      content {
        json
      }
    }
    ... on Table {
      title {
        json
      }
      source
      tableJson
    }
    ... on CallToActionBox {
      content {
        ... on ContentTypeRichText {
      content {
        json
      }
    }
      }
    }
    ... on FlagLinks {
      flagLinksCollection(limit: 35) {
        items {
          __typename
          sys {
            id
          }
          country
          linksTo {
            ... on Page {
              slug
            }
            ... on SubLandingPage {
              slug
              parentSlug
            }
          }
        }
      }
    }
    ... on HeroTextWithServiceLinks {
      circularImage
      image {
          url
          description
      }
      primaryTextField {
        content {
          json
        }
      }
      serviceLinks {
        title {
          json
        }
        serviceLinksCollection {
          items {
            sys {
              id
            }
            content {
              json
            }
          }
        }
      }
    }
  }
}
`;

export const ARTICLE_QUERY = gql`
__typename
sys {
  id
}
seo {
    __typename
    sys {
      id
    }
  title
  description
  image {
    url
    description
  }
  keywords
  noIndex
  noFollow
}
category
title
slug
preamble
image {
  url
  description
}
publicationDate
body {
  json
}
`;

export const SITEMAP_ARTICLE_QUERY = gql`
sys {
  publishedAt
}
slug_sv: slug(locale: "sv")
slug_en: slug(locale: "en")
`;
