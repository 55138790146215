import Image, { ImageLoaderProps, ImageProps } from 'next/image';

interface IContentfulImageProps {
  contentfulWidth?: number;
  contentfulHeight?: number;
  f?: string;
  fit?: string;
  src: string;
  alt: string;
}

function contentfulLoader(
  { src, width, quality }: ImageLoaderProps,
  fit: string,
  f: string,
  contentfulWidth: number,
  contentfulHeight: number
): string {
  const srcString = `${src}?w=${contentfulWidth || width}&q=${quality || 65}${
    contentfulHeight ? `&h=${contentfulHeight}` : ''
  }${fit ? `&fit=${fit}` : ''}${f ? `&f=${f}` : ''}`;

  return srcString;
}

export default function ContentfulImage({
  contentfulWidth,
  contentfulHeight,
  f, // Focus point
  fit,
  src,
  alt,
  ...props
}: IContentfulImageProps & ImageProps) {
  return (
    <Image
      src={src}
      alt={alt}
      loader={(forwardedProps) =>
        contentfulLoader(
          forwardedProps,
          fit,
          f,
          contentfulWidth,
          contentfulHeight
        )
      }
      {...props}
    />
  );
}
