import { ArrowRightIcon } from '@radix-ui/react-icons';
import { Asset } from 'contentful';
import ContentfulImage from '@components/ContentfulImage';
import Date from '@components/Date';
import { IContentfulImage } from '@lib/types/index';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';

interface IArticleTeaserProps {
  title: string;
  publicationDate: string;
  slug: string;
  preamble: string;
  category: string;
  image: IContentfulImage;
  imagePriority: boolean;
}

export default function ArticleTeaser({
  title,
  publicationDate,
  slug,
  preamble,
  category,
  image,
  imagePriority,
}: IArticleTeaserProps) {
  const { t } = useTranslation('articles');
  const tArticles = t('articles');
  const tCategory = t(`category-${category}`);
  const tPublished = t(`published`);
  const tReadMore = t(`common:read-more`);

  return (
    <Link
      as={`/${tArticles}/${slug}`}
      href={`/${tArticles}/[slug]`}
      className='flex-1'
    >
      {image?.url ? (
        <ContentfulImage
          src={image.url}
          alt={image?.description}
          width={392}
          height={221}
          objectFit='cover'
          priority={imagePriority}
        />
      ) : null}
      <div className='mt-2'>
        <Date dateString={publicationDate} prefix={tPublished} />
        {category && (
          <span className='text-xs uppercase'>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <span className='text-interactive-blue'>{tCategory}</span>
          </span>
        )}
      </div>
      <h3 className='my-2 text-2xl italic font-semibold leading-tight text-dark-blue'>
        {title}
      </h3>
      <p>{preamble}</p>
      <p className='flex items-center gap-2 mt-4 text-interactive-blue'>
        <span className='inline-block first-letter:uppercase'>{tReadMore}</span>
        <ArrowRightIcon />
      </p>
    </Link>
  );
}
