import RichTextWrapper from '@components/RichTextWrapper';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { richTextOptions } from '@lib/utils/transformContent';

export default function ArticleBody({ content }) {
  return (
    <RichTextWrapper className={`mt-4`}>
      {documentToReactComponents(content.json, richTextOptions)}
    </RichTextWrapper>
  );
}
