import { ReactNode } from 'react';

export default function RichTextWrapper({
  children,
  className = '',
  ...props
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <div
      className={`prose prose-headings:text-dark-blue prose-h2:text-2xl md:prose-h2:text-3xl prose-h2:mt-4 prose-h2:mb-1${
        className ? ' ' + className : ''
      }`}
      {...props}
    >
      {children}
    </div>
  );
}
