import * as RadixPopover from '@radix-ui/react-popover';

import { Cross1Icon, MagnifyingGlassIcon } from '@radix-ui/react-icons';

import Portal from '@components/Portal';
import { useRouter } from 'next/router';
import { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

export default function SearchPopover() {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const { t } = useTranslation('search');
  const header =
    typeof window !== 'undefined' && document.getElementsByTagName('header')[0];

  function handleSubmit(e) {
    e.preventDefault();
    const value = e?.target?.[0]?.value;

    if (!value) return;

    // TODO Handle if the user is already on the /search page
    // Alternatives: Disable the button? Hide it?

    router.push(
      { pathname: `/${t('search')}`, query: { q: value } },
      `/${t('search')}`
    );
  }

  return (
    <>
      <Portal targetId='portal-target-backdrop'>
        <button
          className={
            isOpen
              ? 'absolute z-10 top-0 left-0 w-full h-full bg-black opacity-80 md:hidden'
              : 'hidden'
          }
          aria-label='Close search menu'
          onClick={() => setIsOpen(false)}
        ></button>
      </Portal>
      <RadixPopover.Root open={isOpen} onOpenChange={setIsOpen} modal={true}>
        <RadixPopover.Trigger asChild>
          <button
            className='flex items-center justify-center cursor-pointer text-dark-blue hover:text-turquoise'
            aria-label={t('search')}
          >
            <MagnifyingGlassIcon className='h-[22px] w-[22px]' />
          </button>
        </RadixPopover.Trigger>
        <RadixPopover.Portal container={header}>
          <RadixPopover.Content
            align='end'
            sideOffset={5}
            // onOpenAutoFocus={(e) => {
            //   const isMobile =
            //     window?.matchMedia('(max-width: 767px)')?.matches;

            //   if (isMobile) return false; // Prevent janky autofocus on mobile
            // }}
            className='absolute w-screen p-4 bg-white border-t border-b rounded-br-md rounded-bl-md min-h-max md:border md:static md:block md:shadow-lg md:rounded-md min-w-max md:w-80 md:max-w-xs border-divider-light'
          >
            <label
              className='block mb-2 text-sm capitalize text-graphite'
              htmlFor='search-input'
            >
              {t('search')}
            </label>
            <form className='ais-SearchBox-form' onSubmit={handleSubmit}>
              <input
                type='text'
                className='block w-full ais-SearchBox-input'
                id='search-input'
                name='search-input'
              />
              <button className='ais-SearchBox-submit' type='submit'>
                <MagnifyingGlassIcon />
              </button>
            </form>
            <RadixPopover.Close className='absolute top-4 right-4'>
              <Cross1Icon />
            </RadixPopover.Close>
          </RadixPopover.Content>
        </RadixPopover.Portal>
      </RadixPopover.Root>
    </>
  );
}
